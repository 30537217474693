.navbar {
  border: 0px;

  li {
    span {
      font-size: 8px;
      padding: 0px 5px;
      position: absolute;
      top: 1px;
      left: 88px;
      color: white;
      z-index: 10;
      background: $main-color;
      font-weight: bold;
      font-family: sans-serif;
      transform: scale(.8, .8);
      animation: jump .5s linear alternate infinite;
    }
  }
}

@keyframes jump {
  0%   {
    transform: translate(0, 0) scale(.8, .8);
  }
  100% {
    transform: translate(0, 0) scale(.9, .8);
  }
}

.navbar-brand {
  width: 150px;
  background-color: $main-color;
  padding: 6px;
  margin-left: 40px !important;
  -webkit-backface-visibility: hidden;
  -webkit-transform: skew(-16deg, 0);
  transform: skew(-16deg, 0);
  transition-property: background;
  transition-timing-function: ease-in-out;
  transition-duration: .3s;
  overflow: hidden;

  img {
    -webkit-transform: skew(16deg, 0) scale(.8, .8) translateX(10px);
    transform: skew(16deg, 0) scale(.8, .8) translateX(10px);
    width: 38px;
    display: inline-block;
  }
  .title {
    -webkit-transform: skew(16deg, 0) scale(.8, .8) translateX(-10px);
    transform: skew(16deg, 0) scale(.8, .8) translateX(-10px);
    font-size: 20px;
    vertical-align: middle;
    display: inline-block;
    letter-spacing: .15em;
    color: #ffffff;
    span {
      font-family: Arial;
      letter-spacing: .16em;
      font-size: 12px;
    }
  }
}

.navbar-brand:hover {
  background-color: #fc571c !important;
}
