$main-color: #FF4A07;

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/slick-carousel/slick/slick.scss";
@import "node_modules/animate.css/animate";
@import "node_modules/magnific-popup/src/css/main";

$slick-dot-color-active: $main-color;
$slick-dot-color: #aaa;
$slick-opacity-not-active: 0.5;
$slick-dot-character: "●";
$slick-dot-size: 18px;
@import "node_modules/slick-carousel/slick/slick-theme.scss";



@import "_menu";
@import "component/_footer";
a, a:hover {
  color: #ffffff;
  text-decoration: none;
}

body {
  color: #ffffff;
  font-weight: lighter;
  font-family: Microsoft JhengHei;
}

section {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-image 3s;
  animation-name: bright-in;
  animation-duration: 1.5s;
  text-align: center;

  h3 {
    margin-top: 35px;
    font-size: 32px;
    color: #000000;
    font-weight: bold;
    letter-spacing: .1em;
  }

  &.top {
    min-height: 700px;
    height: 100vh;
    background-image: url("/images/bg.jpg");
    text-align: center;
    background-attachment: fixed;
    background-position: 50% 100%;
  }

  &.second {
    background-image: url("/images/section2.jpg");
  }

  &.third {
    height: 700px;
    background-image: url("/images/section3.jpg");
    background-attachment: fixed;
    background-position: 50% 100%;
    h3 {
      color: #ffffff;
    }
  }

  &.forth {
    height: 613px;
    h3 {
      margin-top: 100px;
    }
  }


  .sub-title {
    color: #aaa;
    font-size: 15px;
    letter-spacing: .15em;
  }


  .main-description {
    top: 25%;
    position: absolute;
    left: 50%;
    right: 50%;
    font-size: 32px;
    margin-left: -300px;
    div {
      width: 670px;
      overflow: hidden;

      .big {
        font-size: 38px;
      }

      .letter {
        opacity: 0;
        position: relative;
        top: calc(50% - 60px);
        text-shadow: 0px 0px 1px white;
      }

      @for $i from 0 through 26 {
        .letter:nth-child(#{$i + 1}) {
          animation: fade 1.5s #{400+$i*60}ms;
          animation-fill-mode: forwards;
        }
      }

      @keyframes fade {
        from {
          opacity: 0.02;
        }
        to {
          opacity: 1;
        }
      }
    }
  }

  .primary-action {
    position: absolute;
    top: 44%;
    margin-left: -23px;
    width: 250px;
    left: 50%;
    right: 50%;
    color: #ddd;
    margin-left: -132px;
    font-size: 16px;
    padding: 15px 40px;
    border-radius: 80px;
    border: solid 1px #ffffff;
    background-color: transparent;
    box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
    letter-spacing: 2px;
    line-height: 1em;
    transition: .5s;
  }
  .primary-action:hover {
    border: solid 1px $main-color;
    background-color: $main-color;
    transition: .5s;
  }

  .scroll-down {
    padding-top: 50px;
    left: 50%;
    right: 50%;
    margin-left: -50px;
    width: 102px;
    position: absolute;
    bottom: 30px;
    z-index: 2;
    display: inline-block;
    transform: translate(0, -50%);
    color: #fff;
    font: normal 400 12px/1 'Josefin Sans', Microsoft JhengHei;
    letter-spacing: .1em;
    span {
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -14px;
      width: 24px;
      height: 38px;
      border: 1px solid #fff;
      border-radius: 50px;
      box-sizing: border-box;
    }

    .arrow {
      animation: move 1.5s infinite;
      .first {
        bottom: -14px;
      }
      .second {
        bottom: -25px;
      }

      .arrow-down {
        position: absolute;
        width: 10px;
        height: 10px;
        content: '';
        left: 50%;
        margin-left: -7px;
        border-left: 1px solid #fff;
        border-bottom: 1px solid #fff;
        transform: rotate(-45deg);
        box-sizing: border-box;
      }

      @keyframes move {
        50% {
          transform: translateY(4px);
        }
      }
    }


    span::before {
      position: absolute;
      top: 8px;
      left: 50%;
      content: '';
      width: 1px;
      height: 6px;
      background-color: #fff;
      border-radius: 5%;
      box-sizing: border-box;
    }
  }
}

section.even {
  background-color: white;
}

.slick-dotted.slick-slider {
  margin-bottom: 100px;
}

.slick-dots {
  bottom: -40px;
}


.learn-more-container {
  position: relative;
  border-bottom: 1px solid #000000;

  &.even {
    border-bottom: 1px solid $main-color;
    .learn-more {
      background-color: $main-color;
    }
  }

  .learn-more {
    position: relative;
    display: inline-block;
    top: -15px;
    float: right;
    cursor: pointer;
    transform: skew(-16deg, 0);
    background-color: #000000;
    padding: 6px 20px;
    color: #ffffff;

    .text {
      transform: skew(16deg, 0);
      padding: 3px;
      letter-spacing: .1em;
      font-size: 14px;
    }
  }
}

.event-container {
  color: black;
  margin-top: 12px;

  .event {

    width: 380px;
    display: inline-block;
    float: left;
    padding: 10px;
    text-align: left;
    margin-top: 25px;
    margin-bottom: 48px;

    .learn-more-container {
      margin-top: 26px;
      margin-right: 10px;
      .learn-more {
        top: -15px;
        right: -12px;
      }
    }

    .date-triangle {
      &.even {
        border-top: 100px solid $main-color;
      }
      position: absolute;
      width: 0;
      height: 0;
      margin-left: -10px;
      margin-top: -12px;
      border-top: 100px solid #000000;
      border-left: 0px solid transparent;
      border-right: 90px solid transparent;
    }

    .date {
      color: #fff;
      position: absolute;
      z-index: 99;
      font-family: Arial;
      font-style: italic;
      font-weight: 500;
      letter-spacing: .1em;
      margin-left: 8px;
      margin-top: -6px;

      .d {
        font-size: 30px;
      }

      span {
        font-size: 12px;
      }
    }

    .title {
      font-weight: bold;
      padding-botton: 5px;
    }

    .img {
      &.youtube {
        font-size: 50px;
        i {
          color: red;
          position: absolute;
          margin-top: -134px;
          margin-left: 143px;;
        }
      }

      width: 355px;
      height: 230px;
      margin-bottom: 5px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.basic-knowledge {
  z-index: 99;
  .note {
    position: absolute;
    right: 50%;
    left: 50%;
    bottom: 20px;
    color: #8f8d8d;
    width: 302px;
    margin-left: -550px;
  }
  .container {
    position: relative;
  }
  ul {
    z-index: 99;
    list-style-type: none;
    letter-spacing: .1em;
    position: absolute;

    li {
      cursor: pointer;
      visibility: hidden;

      .number {
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        text-align: center;
        padding: 10px;
        color: $main-color;
        border: 2px solid $main-color;
        transition: .5s;
        font-weight: bold;
      }

      &.visible {
        visibility: visible;
      }

      .line {
        width: 200px;
        position: absolute;
        transition: width 2s;
        border-bottom: 1px solid $main-color;
      }
      img {
        position: absolute;
      }
      &:hover{
        .number{
          background-color: $main-color;
          color: #ffffff;
          border: 2px solid #ffffff;
        }
        .sub {
          visibility: visible;
        }
      }
      margin-top: 38px;
      p {
        text-align: left;
        margin-left: 50px;
      }
    }

    &.menu-left {
      img {
        margin-top: 0px;
      }
    }
    &.menu-right {
      right: 82px;
      margin-top: -30px;
      img {
        margin-top: 30px;
      }

      .number {
        position: absolute;
        right: -48px;
      }
      p {
        text-align: right;
      }
    }
  }
  .knowledge-container {
    margin-top: 30px;
    position: absolute;
    min-width: 1140px;
    left: 50%;
    right: 50%;
    margin-left: -570px;
    overflow: hidden;

    img {
      &.main {
        margin: 0 auto;
        width: 630px;
        visibility: visible;
      }
      visibility: hidden;
      &.sub {
        position: absolute;
      }
      &.brand {
        width: 222px;
        top: 28px;
        left: 459px;
      }
      &.maxload {
        width: 50px;
        top: 118px;
        left: 351px;
      }
      &.mountingdirection {
        width: 30px;
        top: 283px;
        left: 289px;
      }
      &.extraload {
        width: 31px;
        top: 382px;
        left: 307px;
      }
      &.madein {
        width: 47.6px;
        top: 457px;
        left: 343px;
      }
      &.specification {
        width: 80px;
        top: 402px;
        left: 324px;
      }
      &.eaglef1 {
        width: 205px;
        top: 552px;
        right: 383px;
      }
      &.manufacturedate {
        width: 19px;
        top: 244px;
        right: 228px;
      }
      &.speccode {
        width: 30px;
        top: 207px;
        right: 231px;
      }
      &.dot {
        width: 25px;
        top: 149px;
        right: 266px;
      }
      &.tubless {
        width: 29px;
        top: 130px;
        right: 263px;
      }
      &.treadwear {
        width: 33px;
        top: 276px;
        right: 206px;
      }
      &.radial {
        width: 26px;
        top: 110px;
        right: 288px;
      }
    }
  }
}

.understand {
  overflow: hidden;
  .description {
    color: #000000;
    margin-top: 25px;
    letter-spacing: .06em;
    line-height: 1.8em;
  }

  .connection {
    visibility: hidden;
    opacity: 0;

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }

  .learn-more-container {
    width: 150px;
    top: 15px;
    cursor: pointer;
    margin: 0 auto;
    top: 30px;
    z-index: 20;
    border: none;
  }

  .point {
    z-index: 999;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background: #E84408;
    border-radius: 100%;
    opacity: 0.6;
    border: 1px solid;
    border-color: #dc724c;
    cursor: pointer;
    animation: Flickering 1200ms infinite ease-in-out;
    &.width {
      left: 427px;
      top: 165px;
    }
    &.flat {
      left: 514px;
      top: 151px;
    }
    &.diameter {
      left: 624px;
      top: 155px;
    }
    &.load {
      left: 690px;
      top: 167px;

    }
    &.v {
      left: 737px;
      top: 180px;
    }
  }

  @keyframes Flickering {
    0% {
      opacity: 0.6;
      border-width: 1px;
    }
    50% {
      opacity: 0.9;
      border-width: 8px;
    }
    100% {
      opacity: 0.6;
      border-width: 1px;
    }
  }

  .tips {
    color: #000000;
    border: 2px solid $main-color;
    padding: 5px;
    position: absolute;
    top: 0;
    background-color: #ffffff;
    width: 295px;
    text-align: left;
    transform: skew(-16deg, 0);
    visibility: hidden;
    z-index: 100;

    &.show {
      visibility: visible;
    }
    &.width {
      left: 100px;
    }
    &.load {
      right: 30px;
    }
    &.v {
      right: 50px;
    }
    &.diameter {
      right: 100px;
    }
    &.flat {
      left: 200px;
    }
    .text {
      transform: skew(16deg, 0);
      padding: 5px 20px;
      width: 292px;
      margin-left: -5px;
      .title {
        font-weight: bold;
        div {
          width: 5px;
          background: $main-color;
          height: 12px;
          display: inline-block;
          margin-right: 5px;
          vertical-align: middle;
          transform: skew(-16deg, 0);
          margin-top: -2px;
        }
      }
    }
  }

  .tire-container {
    position: absolute;
    left: 50%;
    right: 50%;
    bottom: 0px;
    min-width: 1140px;
    margin-left: -570px;
    overflow: hidden;
    img {
      &.main {
        margin: 0 auto;
        margin-top: 30px;
        width: 980px;
      }
      &.flash {
        position: absolute;
        cursor: pointer;
        opacity: .05;
        &.show {
          opacity: 1;
        }
      }
      &.width {
        left: 387px;
        top: 165px;
      }
      &.flat {
        left: 487px;
        top: 154px;
      }
      &.diameter {
        right: 490px;
        top: 158px;
      }
      &.load{
        right: 421px;
        top: 170px;
      }
      &.v{
        right: 385px;
        top: 179px;
      }
    }
  }
}


#map {
  height: 100%;
  width: 100%;
}

.map-container {
  width: 100%;
  height: 400px;
  padding: 0px;
}

.contact {
  .container {
    padding: 30px 0;
    .item-container {
      min-width: 1160px;
      display: inline-block;
      margin: 0 auto;
    }
  }
  .items {
    width: auto;
    float: left;
    font-size: 13px;
    margin-left: 30px;
    p {
      text-align: left;
      margin: 0 8px;
      display: inline-block;
      vertical-align: middle;
    }
    .icon {
      border: 1px solid #ffffff;
      width: 40px;
      height: 40px;
      padding: 8px;
      border-radius: 20px;
      display: inline-block;
      img {
        width: 22px;
        height: 22px;
      }
    }
  }
}

